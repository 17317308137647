<!-- 名片 -->
<template>
    <div style="text-align: left;">
        <el-card>
            <div class="pageCenter apply-container">
                <h3 class="text-center p-50">{{hasEdit==false?'申请开通名片':'编辑名片信息'}}</h3>
                <div>
                    <el-form :model="enterApplyForm" :rules="enterApplyFormRules" ref="enterApplyForm" label-width="120px">
                        <el-form-item label="名片类型" prop="cardCategory">
                            <el-select v-model="enterApplyForm.cardCategory" placeholder="请选择" style="width: 100%;">
                                <el-option v-for="item in cardCategoryList" :key="item.id" :label="item.categoryName" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="姓名" prop="cardName">
                            <el-input v-model="enterApplyForm.cardName" autocomplete="off" placeholder="填写姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="头像" prop="cardPic">
                            <upload-image @uploadImageBack="uploadImageBack">
                                <img v-if="enterApplyForm.cardPic" :src="$oucy.ossUrl+enterApplyForm.cardPic" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </upload-image>
                        </el-form-item>
                        <el-form-item label="职位" prop="cardJob">
                            <el-input v-model="enterApplyForm.cardJob" autocomplete="off" placeholder="填写职位"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="cardPhone">
                            <el-input v-model="enterApplyForm.cardPhone" autocomplete="off" placeholder="填写电话"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱地址" prop="cardEmail">
                            <el-input v-model="enterApplyForm.cardEmail" autocomplete="off" placeholder="填写邮箱地址"></el-input>
                        </el-form-item>
                        <el-form-item label="城市" v-if="isShowCityCode" prop="cityCode">
                            <el-cascader v-model="enterApplyForm.cityCode" style="width: 100%" :props="cityProps" :placeholder="enterApplyForm.cityPlaceholder"></el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="cardAdd">
                            <el-input v-model="enterApplyForm.cardAdd" type="text" :rows="2" autocomplete="off" placeholder="填写详细地址" @focus="enterApplyForm.cardLat?'':onAddrSelect(addrPoint.JYDZ)"/>
                            <el-button @click="onAddrSelect(addrPoint.JYDZ)" icon="el-icon-location" type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item label="个人简介" prop="cardSelf">
                            <div style="">
                                <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" placeholder="请输入个人简介" v-model="enterApplyForm.cardSelf">
                                </el-input>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="submit" type="primary" v-if="hasEdit">保存名片</el-button>
                            <el-button @click="submit" type="primary" v-else>提交申请</el-button>
                            <el-button @click="$oucy.back()">取 消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
        <!-- <input style="display: none" :id="hiddenFileInputId" type="file" name="file" multiple accept="image/jpg, image/jpeg, image/png, image/gif" @change="realEditorUploadImg" /> -->
        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle" />
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import addr from "@/components/Addr";
import uploadImage from "@/components/uploadImage";
import { localSet, localGet, localDel } from "@/store/store"
import { ltIdentity, cardCategory, cardEnterprise, cardPerson ,userMaterial,cardEmployee} from "@/service"
export default {
    name: "EnterpriseApply1111",
    components: {
        addr,
        uploadImage
    },
    data() {
        return {
            identitys: [],
            sites: [],
            enterpriseEnterStatus: null,
            enterApplyForm: {
                id: null,
                cardPic: null,
                cardCategory: null, //    名片分类
                cardName: null, //    个人名片姓名  
                cardJob: null, // 个人名片职位  
                cardPhone: null, //   个人联系电话
                cardEmail: null,
                cardRegion: null,
                cardAdd: null, // 联系地址        
                cardLat: null, // 纬度  经度 113.113594 纬度 23.009505
                cardLon: null, // 经度  
                cardSelf: null, //    个人介绍
                cityCode: null, //    城市
            },
            enterApplyFormRules: {
                cardCategory: [
                    { required: true, message: '请选择名片类型', trigger: 'blur' },
                ],
                cardPic: [
                    { required: true, message: '请选择头像', trigger: 'blur' },
                ],
                cardName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                cardJob: [
                    { required: true, message: '请输入职位', trigger: 'blur' },
                ],
                cardPhone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                ],
                cityCode: [
                    { required: true, message: '请选择城市', trigger: 'blur' },
                ],
                cardAdd: [
                    { required: true, message: '请选择详细地址', trigger: 'blur' },
                ],
                cardSelf: [
                    { required: true, message: '请输入个人简介', trigger: 'blur' },
                ]
            },


            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },
            addrSelectVisible: false,
            addrSelectPoint: null,
            addrPoint: {
                JYDZ: 1,
                GCDZ: 2,
                ZTDZ: 3
            },
            enterprise: null,
            enterpriseId: null,
            ltIdentityList: [],
            isShowCityCode: false,
            cardEnterpriseDetail: {},
            cardCategoryList: [],
            hasEdit: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }else{
            // this.getCardCategoryList()
            // this.isShowCityCode = true
        }
            this.getMyCardPersonDetail()

    },
    methods: {
        // 查个人名片详情
        getMyCardPersonDetail() {
            cardPerson.getMyCardPersonDetail({}).then(res => {
                // 有名片数据
                if (res) {
                    this.enterApplyForm = Object.assign(this.enterApplyForm,res)
                    this.hasEdit = true
                    this.enterApplyForm.cityCode = res.chinaDto && res.chinaDto.idPath
                    this.enterApplyForm.cardLat = res.cardLatitude
                    this.enterApplyForm.cardLon = res.cardLontitude
                    this.isShowCityCode = true
                } else {
                    this.hasEdit = false
                    setTimeout(()=>{
                        this.isShowCityCode = true
                    },1000*5)
                    // 没有个人名片的拿企业下的员工名片回填
                    userMaterial.queryUserMaterial({}).then(res=>{
                        if(res && res.cardEmployeeId){
                            cardEmployee.getEmployeeDetail({id:res.cardEmployeeId,type:!true}).then(res=>{
                                if(res){
                                    this.enterApplyForm.cardPic = res.employeePic
                                    this.enterApplyForm.cardName = res.employeeName
                                    this.enterApplyForm.cardJob = res.employeeJob
                                    this.enterApplyForm.cardPhone = res.employeePhone
                                    this.enterApplyForm.cardEmail = res.employeeEmail
                                    // this.addressName = res.ltChinaOfEmployeeCardRegion ? res.ltChinaOfEmployeeCardRegion.namePath :''
                                    this.enterApplyForm.cardAdd = res.employeeAdd
                                    this.enterApplyForm.cardSelf = res.employeeSignature
                                    this.enterApplyForm.cityCode = res.ltChinaOfEmployeeCardRegion && res.ltChinaOfEmployeeCardRegion.idPath
                                    this.enterApplyForm.cardLat = res.employeeLatitude
                                    this.enterApplyForm.cardLon = res.employeeLontitude
                                    this.isShowCityCode = true
                                }
                            })
                        }
                    })
                    
                }
                this.getCardCategoryList()
            })
        },
        // 发布名片
        createCardPerson() {
            cardPerson.createCardPerson(this.enterApplyForm).then(res => {
                this.$alert('发布成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        // 修改名片
        updateCardPerson() {
            cardPerson.updateCardPerson(this.enterApplyForm).then(res => {
                // 有名片数据
                this.$alert('修改成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        // 查询名片分类列表
        getCardCategoryList() {
            cardCategory.getCardCategoryList({ categoryType: 0 }).then(res => {
                this.cardCategoryList = res
                if (res && res.length) {
                    this.enterApplyForm.cardCategory = res[0].id
                }
            })
        },
        uploadImageBack(v) {
            this.enterApplyForm.cardPic = v
            console.log(v)
        },

        submit: function() {
            const me = this;
            console.log(me.enterApplyForm);
            me.$refs["enterApplyForm"].validate((valid) => {
                if (valid) {
                    this.enterApplyForm.cardRegion = this.enterApplyForm.cityCode[this.enterApplyForm.cityCode.length - +1]
                    if (me.hasEdit) {
                        this.$confirm(`提交修改后个人名将进入审核状态，是否继续`, '提交修改', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$oucy.keywordFiltr(this.enterApplyForm).then(res=>{
                                this.updateCardPerson()
                            },err=>{})
                        }).catch(() => {});
                    } else {
                        this.$oucy.keywordFiltr(this.enterApplyForm).then(res=>{
                            this.createCardPerson()
                        },err=>{})
                    }
                }
            });
        },

        onAddrSelect: function(point) {
            this.addrSelectVisible = true;
            this.addrSelectPoint = point;
        },
        addrSelectHandle: function(addr) {
            console.log('addr::', addr)
            const me = this;
            this.addrSelectVisible = false;
            let thisAddr = addr.address + '(' + addr.name + ')';
            if (!!me.addrSelectPoint) {
                me.enterApplyForm.cardLat = addr.location.lat
                me.enterApplyForm.cardLon = addr.location.lng
                me.enterApplyForm.cardAdd = thisAddr;
            }
        },



    }
}
</script>
<style scoped>
.apply-container {
    width: 500px;
    min-height: 600px;
    padding: 0 0 50px 0;
}

.editor {
    line-height: normal !important;
    height: 200px;
}

.editor>>>.ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0;
}

.editor>>>.ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
}

.search-addr-name {
    color: #000000;
}

.search-addr-address {
    color: #5c5b75;
    font-size: 12px;
    padding: 0 10px;
}

.search-addr-tel {
    font-style: italic;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.cropper-content .cropper {
    width: auto;
    height: 300px;
}
</style>